<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <reseller-selector
          v-if="
            !filterReseller &&
            $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
          "
          @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
        />
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
        <div class="flex-grow-0">
          <base-button
            class="notification"
            type="info"
            icon
            size="sm"
            :title="$t('COMMON.SHOW_DELETED_ITEMS')"
            :class="{ active: onlyTrashed }"
            @click="onlyTrashed = !onlyTrashed"
          >
            <span class="btn-inner--icon">
              <i class="fa-light fa-trash"></i>
            </span>
          </base-button>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light has-image"
            :data="organizations"
            @sort-change="sortChange"
            :row-class-name="setDeletedClass"
            @row-click="
              (row) => {
                viewOrganization(row);
              }
            "
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column width="75">
              <template v-slot="{ row }">
                <div class="image">
                  <img
                    v-if="row.logo"
                    :src="row.logo"
                    class="avatar rounded-circle"
                  />
                  <span class="default-icon">
                    <i class="far fa-camera"></i>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="220"
            />
            <el-table-column
              :label="$t('COMMON.RESELLER')"
              sortable="custom"
              min-width="220"
              v-if="
                !filterReseller &&
                $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
              "
            >
              <template v-slot="{ row }">
                <reseller :reseller="row.reseller" />
              </template>
            </el-table-column>
            <el-table-column :label="$t('COMMON.OWNER')" min-width="220">
              <template v-slot="{ row }">
                <template v-if="!!row.owner">
                  <object-link
                    v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
                    :object="row.owner"
                  />
                  <span v-else>{{ $objectDenomination(row.owner) }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('ORGANIZATIONS.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $timeZoneDateFormat(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="120">
              <div
                v-if="!isDeleted(row)"
                slot-scope="{ row }"
                class="table-actions"
              >
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  :class="{
                    disabled: !$currentUserCan(
                      $permissions.PERM_VIEW_ORGANIZATIONS
                    ),
                  }"
                >
                  <a
                    type="text"
                    @click="viewOrganization(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i
                      class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                    ></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  :class="{
                    disabled: !$currentUserCan(
                      $permissions.PERM_DELETE_ORGANIZATIONS
                    ),
                  }"
                >
                  <a
                    type="text"
                    @click="deleteOrganization(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fa-light fa-trash-can"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";
import ResellerSelector from "@/components/ResellerSelector.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import trashedMixin from "@/mixins/trashed-mixin";

export default {
  name: "organization-list-table",

  components: {
    BasePagination,
    ResellerSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin, trashedMixin],

  props: {
    filterReseller: {
      type: String,
      default: null,
      description: "reseller id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      organizations: [],
      loading: true,
      selectedReseller: null,
      onlyTrashed: false,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    onlyTrashed: {
      handler: "getList",
      immediate: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "owner,reseller",
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.selectedReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.selectedReseller },
          };
        }
        if (this.onlyTrashed) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              only_trashed: true,
            },
          };
        }

        await this.$store.dispatch("organizations/list", params);
        this.organizations = this.$store.getters["organizations/list"];
        this.total = this.$store.getters["organizations/listTotal"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteOrganization(organization) {
      this.$emit("onDeleteOrganization", organization);
    },

    viewOrganization(organization) {
      if (this.isDeleted(location)) {
        return;
      }
      this.$emit("onViewOrganization", organization);
    },

    editOrganization(organization) {
      this.$emit("onEditOrganization", organization);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
